.hpm-browserNotSupported {

  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  font-family: 'Helvetica Neue', 'Arial', serif;
  text-align: center;
  margin: 20px 0;

  .hpm-notSupportedHeadline {
    font-size: 40px;
  }

  .hpm-notSupportedText {
    margin-top: 10px;
    font-size: 14px;
    white-space: pre-wrap;
  }

  .hpm-supportedBrowsers {
    $icon-size: 100px;
    $padding: 20px;

    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .hpm-edge {
      background-image: url("../../../src/assets/images/browser/edge.png");
      background-repeat: no-repeat;
      background-position: center;
      height: $icon-size;
      width: $icon-size;
      background-size: $icon-size;
      padding: $padding;

    }

    .hpm-firefox {
      background-image: url("../../../src/assets/images/browser/firefox.png");
      background-repeat: no-repeat;
      background-position: center;
      height: $icon-size;
      width: $icon-size;
      background-size: $icon-size;
      padding: $padding;
    }

    .hpm-chrome {
      background-image: url("../../../src/assets/images/browser/chrome.png");
      background-repeat: no-repeat;
      background-position: center;
      height: $icon-size;
      width: $icon-size;
      background-size: $icon-size;
      padding: $padding;
    }

    .hpm-safari {
      background-image: url("../../../src/assets/images/browser/safari.svg");
      background-repeat: no-repeat;
      background-position: center;
      height: $icon-size;
      width: $icon-size;
      background-size: $icon-size;
      padding: $padding;
    }
  }

}
